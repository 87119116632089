<template>
  <el-drawer
    v-model="isShowFilterDrawer"
    :show-close="false"
    modal-class="elv-valuation-filter-overlay"
    class="elv-valuation-filter-drawer-filter"
    :before-close="onCheckDrawerStatus"
    :size="360"
  >
    <template #header="{ titleId }">
      <h4 :id="titleId" class="elv-report-valuation-drawer-filter-header__title">
        {{ t('common.filter') }}
      </h4>
      <div class="elv-report-valuation-drawer-filter-header__operating">
        <div class="elv-report-valuation-drawer-filter-header__clear">
          <SvgIcon name="reset" width="14" height="14" />
          <span @click="onClearData">{{ t('button.clear') }}</span>
        </div>
        <SvgIcon
          name="close"
          width="20"
          height="20"
          class="elv-report-valuation-drawer-filter-header__close"
          @click="onCheckDrawerStatus"
        />
      </div>
    </template>
    <el-scrollbar v-loading="loading" wrap-class="elv-valuation-drawer-filter-wrap">
      <div v-if="props.filterType !== valuationFilterSaveType.VALIDATOR" class="elv-valuation-drawer-filter-item">
        <div class="elv-valuation-drawer-filter-item-title">
          <p>{{ t('valuation.future.origin') }}</p>
        </div>
        <ElvSelect
          v-model="filterData.origin"
          multiple
          clearable
          collapse-tags
          collapse-tags-tooltip
          :max-collapse-tags="2"
          width="328px"
          :class="{ 'is-unEmpty': filterData?.origin?.length }"
          :placeholder="`${t('common.select')}...`"
          popper-class="elv-select-valuation-filter-dropdown"
          :popper-append-to-body="false"
          :options="adjustmentOriginOptions"
        />
      </div>

      <div class="elv-valuation-drawer-filter-item">
        <div class="elv-valuation-drawer-filter-item-title">
          <p>{{ t('valuation.crypto.adjustingId') }}</p>
        </div>
        <el-input
          v-model.trim="filterData.adjustmentNo"
          clearable
          :placeholder="`${t('common.input')}...`"
          :style="{ width: '328px' }"
          :class="{
            'is-unEmpty': filterData?.adjustmentNo
          }"
        />
      </div>

      <div class="elv-valuation-drawer-filter-item">
        <div class="elv-valuation-drawer-filter-item-title">
          <p>{{ t('valuation.future.adjustingDate') }}</p>
        </div>
        <el-select
          v-model="filterData.datetime.operator"
          :class="{
            'is-unEmpty': filterData?.datetime?.operator || filterData?.datetime?.value?.length,
            'is-select-value': filterData?.datetime?.operator
          }"
          class="is-not-underlined"
          :style="{ width: '328px' }"
          :placeholder="`${t('common.select')}...`"
          popper-class="elv-select-valuation-filter-dropdown"
          :popper-append-to-body="false"
          @change="onChangeDateType"
        >
          <el-option
            v-for="(i, index) in dateTimeOptions"
            :key="index"
            :label="capitalize(transformI18n(i?.label))"
            :value="i?.value"
          />
        </el-select>
        <div
          v-show="filterData?.datetime?.operator"
          class="elv-select-valuation-filter-date-picker"
          :class="{ 'is-unEmpty': filterData.datetime.value.length || filterData?.datetime?.operator }"
        >
          <DatePicker
            ref="datePickerRef"
            v-model:value="dateValue"
            :type="filterData?.datetime?.operator === 'BETWEEN' ? 'range' : 'date'"
            :picker="'date'"
            @change="onRangeChange"
          />
        </div>
      </div>

      <div class="elv-valuation-drawer-filter-item">
        <div class="elv-valuation-drawer-filter-item-title">
          <p>{{ t('common.account') }}</p>
        </div>
        <ElvSelect
          v-model="filterData.entityAccountIds"
          width="328px"
          multiple
          clearable
          collapse-tags
          collapse-tags-tooltip
          :max-collapse-tags="2"
          :placeholder="`${t('common.select')}...`"
          :class="{
            'is-unEmpty': filterData?.entityAccountIds?.length
          }"
          :options="accountNormalOptions"
        />
      </div>

      <div v-if="props.filterType !== valuationFilterSaveType.VALIDATOR" class="elv-valuation-drawer-filter-item">
        <div class="elv-valuation-drawer-filter-item-title">
          <p>
            {{ t('valuation.future.symbol') }}
          </p>
        </div>
        <ElvSelect
          v-model="filterData.assetIds"
          multiple
          clearable
          collapse-tags
          collapse-tags-tooltip
          :max-collapse-tags="2"
          width="328px"
          :class="{ 'is-unEmpty': filterData?.assetIds?.length }"
          :placeholder="`${t('common.select')}...`"
          popper-class="elv-select-valuation-filter-dropdown"
          :popper-append-to-body="false"
          :options="derivateOptions"
        />
      </div>
      <div v-if="props.filterType === valuationFilterSaveType.FUTURE" class="elv-valuation-drawer-filter-item">
        <div class="elv-valuation-drawer-filter-item-title">
          <p>{{ t('report.positionAndSide') }}</p>
        </div>
        <ElvSelect
          v-model="filterData.positionSide"
          multiple
          clearable
          collapse-tags
          collapse-tags-tooltip
          :max-collapse-tags="2"
          width="328px"
          :placeholder="`${t('common.select')}...`"
          :class="{ 'is-unEmpty': filterData?.positionSide?.length }"
          popper-class="elv-select-valuation-filter-dropdown elv-select-valuation-filter-dropdown__position"
          :popper-append-to-body="false"
          :options="positionOptions"
        />
      </div>

      <div v-if="props.filterType === valuationFilterSaveType.FUTURE" class="elv-valuation-drawer-filter-item">
        <div class="elv-valuation-drawer-filter-item-title">
          <p>{{ t('valuation.assets.marginAsset') }}</p>
        </div>
        <ElvSelect
          v-model="filterData.currencyList"
          filterable
          remote
          clearable
          :loading="searchAssetLoading"
          width="328px"
          multiple
          collapse-tags
          :max-collapse-tags="2"
          collapse-tags-tooltip
          :class="{ 'is-unEmpty': filterData?.currencyList?.length }"
          :remote-method="remoteCurrencyMethod"
          :placeholder="`${t('common.select')}...`"
          popper-class="elv-select-valuation-filter-dropdown"
          :options="currenciesData"
          @change="onChangeCurrency()"
          @visible-change="changeSelectPopover"
        />
      </div>

      <div v-if="props.filterType === valuationFilterSaveType.OPTIONS" class="elv-valuation-drawer-filter-item">
        <div class="elv-valuation-drawer-filter-item-title">
          <p>{{ t('valuation.assets.quotaAsset') }}</p>
        </div>
        <ElvSelect
          v-model="filterData.currencyList"
          filterable
          remote
          clearable
          :loading="searchAssetLoading"
          width="328px"
          multiple
          collapse-tags
          :max-collapse-tags="2"
          collapse-tags-tooltip
          :class="{ 'is-unEmpty': filterData?.currencyList?.length }"
          :remote-method="remoteCurrencyMethod"
          :placeholder="`${t('common.select')}...`"
          popper-class="elv-select-valuation-filter-dropdown"
          :options="currenciesData"
          @change="onChangeCurrency()"
          @visible-change="changeSelectPopover"
        />
      </div>

      <div class="elv-valuation-drawer-filter-item">
        <div class="elv-valuation-drawer-filter-item-title">
          <p>
            {{
              props.filterType === valuationFilterSaveType.VALIDATOR
                ? t('valuation.validator.rewardsChange')
                : t('valuation.future.unrealizedAndChange')
            }}
          </p>
        </div>
        <ElvSelect
          v-model="filterData.amount.operator"
          width="328px"
          popper-class="elv-select-valuation-filter-dropdown"
          :class="{
            'is-unEmpty': filterData?.amount?.value?.length,
            'is-select-value': filterData?.amount?.operator
          }"
          class="is-not-underlined"
          :placeholder="`${t('common.select')}...`"
          :popper-append-to-body="false"
          :options="amountOptions"
          @change="onChangeAmountType(filterData.amount.operator, 'amount')"
        />
        <div
          v-show="filterData?.amount?.operator"
          class="elv-select-valuation-filter-amount"
          :class="{
            'is-unEmpty': filterData.amount.value.length,
            'is-between': filterData?.amount?.operator === 'BETWEEN'
          }"
        >
          <el-input
            v-model.trim="filterData.amount.value[0]"
            :formatter="(value: string) => formatNumberWithCommas(value)"
            :parser="(value: string) => value.replace(/\$\s?|(,*)/g, '')"
          /><el-input
            v-show="filterData?.amount?.operator === 'BETWEEN'"
            v-model.trim="filterData.amount.value[1]"
            :formatter="(value: string) => formatNumberWithCommas(value)"
            :parser="(value: string) => value.replace(/\$\s?|(,*)/g, '')"
          />
        </div>
      </div>

      <div class="elv-valuation-drawer-filter-item">
        <div class="elv-valuation-drawer-filter-item-title">
          <p>
            {{
              props.filterType === valuationFilterSaveType.VALIDATOR
                ? t('valuation.validator.rewardsChange')
                : t('valuation.future.unrealizedAndChange')
            }}
            ({{ entityStore.entityDetail?.defaultCurrency }})
          </p>
        </div>
        <ElvSelect
          v-model="filterData.amountFC.operator"
          width="328px"
          popper-class="elv-select-valuation-filter-dropdown"
          :class="{
            'is-unEmpty': filterData?.amountFC?.value?.length,
            'is-select-value': filterData?.amountFC?.operator
          }"
          class="is-not-underlined"
          :placeholder="`${t('common.select')}...`"
          :popper-append-to-body="false"
          :options="amountOptions"
          @change="onChangeAmountType(filterData.amountFC.operator, 'amountFC')"
        />

        <div
          v-show="filterData?.amountFC?.operator"
          class="elv-select-valuation-filter-amount"
          :class="{
            'is-unEmpty': filterData.amountFC.value.length,
            'is-between': filterData?.amountFC?.operator === 'BETWEEN'
          }"
        >
          <el-input
            v-model.trim="filterData.amountFC.value[0]"
            :formatter="(value: string) => formatNumberWithCommas(value)"
            :parser="(value: string) => value.replace(/\$\s?|(,*)/g, '')"
          /><el-input
            v-show="filterData?.amountFC?.operator === 'BETWEEN'"
            v-model.trim="filterData.amountFC.value[1]"
            :formatter="(value: string) => formatNumberWithCommas(value)"
            :parser="(value: string) => value.replace(/\$\s?|(,*)/g, '')"
          />
        </div>
      </div>
    </el-scrollbar>
    <template #footer>
      <elv-button
        :loading="saveLoading"
        type="primary"
        round
        width="204"
        height="36"
        class="elv-report-transaction-drawer-filter-footer__save"
        @click="onSave"
      >
        {{ t('button.apply') }}
      </elv-button>
    </template>
  </el-drawer>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import ConfigApi from '@/api/ConfigApi'
import { ElMessage } from 'element-plus'
import { positionOptions } from '@/config'
import ValuationApi from '@/api/ValuationApi'
import { $t, transformI18n } from '@/i18n/index'
import { capitalize, cloneDeep } from 'lodash-es'
import { formatNumberWithCommas } from '@/lib/utils'
import ElvSelect from '@/components/Base/ElvSelect.vue'
import { useEntityStore } from '@/stores/modules/entity'
import { useAccountStore } from '@/stores/modules/accounts'
import { useValuationStore } from '@/stores/modules/valuation'
import DatePicker from '../../../Transactions/components/DatePicker.vue'
import { adjustmentOriginOptions, valuationFilterSaveType } from '@/config/valuation'

type OptionType = {
  id?: string
  value: string
  label: string
  icon?: string
  alt?: string
  isCheck?: boolean
  isDeleted?: boolean
  suffixName?: string
}

const emit = defineEmits(['onFilterData'])
const props = defineProps({
  filterType: {
    type: String,
    default: valuationFilterSaveType.FUTURE
  }
})

const { t } = useI18n()
const route = useRoute()
const entityStore = useEntityStore()
const accountStore = useAccountStore()
const valuationStore = useValuationStore()

const dateValue = ref()
const datePickerRef = useTemplateRef('datePickerRef')
const loading = ref(false)
const saveLoading = ref(false)
const isShowFilterDrawer = ref(false)
const searchAssetLoading = ref(false)
const currenciesData = ref<OptionType[]>([])
const derivateOptions = ref<OptionType[]>([])
const checkedAssetInfoList = ref<OptionType[]>([])
const recommendCurrenciesData = ref<Array<OptionType>>([])

const filterData = ref<any>({
  origin: [],
  adjustmentNo: '',
  datetime: {
    operator: '',
    value: []
  },
  entityAccountIds: [],
  assetIds: [],
  positionSide: [],
  currencyList: [],
  amount: {
    operator: '',
    value: []
  },
  amountFC: {
    operator: '',
    value: []
  }
})

const dateTimeOptions = [
  { label: $t('common.on'), value: 'ON' },
  { label: $t('common.after'), value: 'AFTER' },
  { label: $t('common.before'), value: 'BEFORE' },
  { label: $t('common.between'), value: 'BETWEEN' }
]

const amountOptions = [
  { label: $t('common.equals'), value: 'EQ' },
  { label: $t('common.More than'), value: 'GT' },
  { label: $t('common.Less than'), value: 'LT' },
  { label: $t('common.within'), value: 'BETWEEN' }
]

const entityId = computed(() => {
  return String(route.params?.entityId as string)
})

const accountNormalOptions = computed(() => {
  const accountList = accountStore.accountList.filter((item: any) => {
    return item.status === 'NORMAL'
  })
  return accountList.map((item: any) => {
    return {
      id: item.entityAccountId,
      value: item.entityAccountId,
      label: item.name,
      icon: item.platform?.logo,
      isCheck: false
    }
  })
})

/**
 * 调账周期类型变化触发
 * @param val 日期筛选类型 key of dateTimeOptions.value
 */
const onChangeDateType = (val: string) => {
  filterData.value.datetime.value = []
  if (val === 'BETWEEN') {
    dateValue.value = []
  } else {
    dateValue.value = ''
  }
}

/**
 * 调账周期日期变化触发
 * @param value 日期筛选值
 */
// eslint-disable-next-line no-unused-vars
const onRangeChange = (value: any) => {
  if (filterData.value.datetime.operator === 'BETWEEN') {
    filterData.value.datetime.value = value !== null ? value : []
  } else {
    filterData.value.datetime.value = value !== null ? [value] : []
  }
}

/**
 * Amount筛选类型变化触发
 * @param val Amount筛选类型 key of amountOptions.value
 * @param key Amount具体数值
 */
const onChangeAmountType = (val: string, key: string) => {
  if (val === 'BETWEEN') {
    filterData.value[key].value = ['', '']
  } else {
    filterData.value[key].value = ['']
  }
}

/**
 * 清除筛选数据
 */
const onClearData = () => {
  filterData.value = {
    origin: [],
    adjustmentNo: '',
    datetime: {
      operator: '',
      value: []
    },
    entityAccountIds: [],
    assetIds: [],
    positionSide: [],
    currencyList: [],
    amount: {
      operator: '',
      value: []
    },
    amountFC: {
      operator: '',
      value: []
    }
  }
  dateValue.value = ''
}

/**
 * 初始化筛选页面，读取本地数据，进行数据反显
 */
const initFilterView = async () => {
  try {
    await nextTick()
    const filterSaveData = valuationStore.valuationFilterList.find((item: any) => item.type === props.filterType)

    if (filterSaveData) {
      const currentFilterData = filterSaveData.list.find((item: any) => item.entityId === entityId.value)?.data
      if (currentFilterData) {
        const {
          origin = [],
          adjustmentNo = '',
          datetime = { operator: '', value: [] as any },
          entityAccountIds = [],
          assetIds = [],
          positionSide = [],
          currencyList = [],
          amount = { operator: '', value: [] },
          amountFC = { operator: '', value: [] },
          checkedAssetInfoList: newCheckedAssetInfoList = []
        } = currentFilterData
        if (datetime.operator === 'BETWEEN') {
          dateValue.value = datetime.value
        } else {
          dateValue.value = datetime.value?.length ? datetime.value[0] : ''
        }
        filterData.value = {
          origin,
          adjustmentNo,
          datetime: datetime.operator && datetime.value.length ? datetime : { operator: '', value: [] },
          entityAccountIds,
          assetIds,
          positionSide,
          currencyList,
          amount: amount.operator && amount.value.length ? amount : { operator: '', value: [] },
          amountFC: amountFC.operator && amountFC.value.length ? amountFC : { operator: '', value: [] }
        }

        newCheckedAssetInfoList.forEach((assetItem: any) => {
          if (!currenciesData.value.some((item) => item.value === assetItem.value)) {
            currenciesData.value.push(assetItem)
          }
        })

        currenciesData.value.forEach((item) => {
          item.isCheck = filterData.value.currencyList.includes(item.value)
        })
      } else {
        onClearData()
      }
    } else {
      onClearData()
    }
  } catch (error) {
    ElMessage.error('加载筛选数据失败')
    console.error(error)
  }
}

/**
 * 获取Future/Options类型资产，用作Symbol筛选
 */
const getDerivateList = async () => {
  try {
    const res = await ValuationApi.getDerivativeList(entityId.value, { limit: 500 })
    derivateOptions.value = res?.data.map((item) => {
      return {
        id: item.asset.assetId || '',
        value: item.asset.assetId || '',
        label: item.name || '',
        icon: item.platform?.logo || '',
        alt: item.platform?.logo || '',
        isCheck: filterData.value.assetIds.includes(item.asset?.assetId || '')
      }
    })
  } catch (error: any) {
    ElMessage.error(error.message)
    console.error(error)
  }
}

/**
 * 请求获取币种信息
 * @param params 请求币种信息参数
 * @property params.recommend 是否推荐
 */
const getRecommendCurrency = async (params: any) => {
  const searchList = await ConfigApi.searchCurrencyList(params)
  currenciesData.value = searchList.data.map((item) => ({
    id: item.underlyingCurrencyId,
    value: item.symbol,
    label: item.showSymbol,
    suffixName: item.showSymbol,
    icon: item.logo,
    alt: item.logo || '',
    isCheck: false
  }))
  if (params?.recommend) {
    recommendCurrenciesData.value = cloneDeep(currenciesData.value)
  }
}

/**
 * 远程搜索币种信息
 * @param query 币种搜索关键字
 */
const remoteCurrencyMethod = async (query: string) => {
  if (query) {
    searchAssetLoading.value = true
    const params: any = {
      recommend: false,
      keywords: query ? [query] : [],
      type: 'CRYPTO',
      entityId: entityId.value
    }
    try {
      await getRecommendCurrency(params)
    } catch (error) {
      console.log(error)
    } finally {
      searchAssetLoading.value = false
    }
  } else {
    currenciesData.value = cloneDeep(recommendCurrenciesData.value)
  }
}

/**
 * 币种选择器选择弹窗显示状态变化
 * @param showStatus 显示状态
 */
const changeSelectPopover = (showStatus: boolean) => {
  if (checkedAssetInfoList.value.length) {
    checkedAssetInfoList.value.forEach((assetItem) => {
      const index = currenciesData.value.findIndex((item) => item.value === assetItem.value)
      if (index === -1) {
        currenciesData.value.push(assetItem)
      }
    })
  }
  if (showStatus) {
    currenciesData.value.forEach((item) => {
      item.isCheck = filterData.value.currencyList.includes(item.value)
    })
    return
  }
}

const uniqueByValue = (array: OptionType[]) => {
  const seen: any = {}
  return array.reduce((acc: OptionType[], item) => {
    if (!seen[item.value]) {
      seen[item.value] = true
      acc.push(item)
    }
    return acc
  }, [])
}

/**
 * 切换币种触发
 */
const onChangeCurrency = () => {
  checkedAssetInfoList.value = checkedAssetInfoList.value.filter((item) => {
    return filterData.value.currencyList.includes(item.value)
  })
  const assetList = currenciesData.value.filter((item) => {
    return filterData.value.currencyList.includes(item.value)
  })
  checkedAssetInfoList.value = checkedAssetInfoList.value.concat(assetList)
  checkedAssetInfoList.value = uniqueByValue(checkedAssetInfoList.value)
}

/**
 * 读取本地筛选数据，进行筛选数据初始化
 */
const initFilterCalculationData = async () => {
  if (isShowFilterDrawer.value) {
    loading.value = true
    try {
      initFilterView()
      await getDerivateList()
      await getRecommendCurrency({ recommend: true, type: 'CRYPTO' })
    } catch (error: any) {
      ElMessage.error(error.message)
    } finally {
      loading.value = false
    }
  }
}

/**
 * 切换筛选Calculation Drawer显示状态
 */
const onCheckDrawerStatus = async () => {
  isShowFilterDrawer.value = !isShowFilterDrawer.value
  initFilterCalculationData()
}

/**
 * 保存筛选数据，进行调账数据筛选
 */
const onSave = async () => {
  try {
    saveLoading.value = true
    if (checkedAssetInfoList.value.length) {
      filterData.value.checkedAssetInfoList = checkedAssetInfoList.value
    }
    const newFilterData = JSON.parse(JSON.stringify(filterData.value))
    Object.keys(newFilterData).forEach((item: any) => {
      if (Array.isArray(newFilterData[item]) && !newFilterData[item].length) {
        delete newFilterData[item]
      } else if (['datetime', 'amount', 'amountFC'].includes(item)) {
        if (
          !newFilterData[item].operator ||
          !newFilterData[item].value.length ||
          newFilterData[item].value.some((i: any) => !i)
        ) {
          delete newFilterData[item]
        }
      } else if (!newFilterData[item]) {
        delete newFilterData[item]
      }
    })
    valuationStore.editCalculationFilter(entityId.value, newFilterData, ['checkedAssetInfoList'], props.filterType)
    emit('onFilterData')
    onCheckDrawerStatus()
  } catch (error: any) {
    ElMessage.error(error.message)
    console.error(error)
  } finally {
    saveLoading.value = false
  }
}

defineExpose({ onCheckDrawerStatus })
</script>

<style lang="scss" scoped>
.elv-valuation-filter-drawer-filter {
  .elv-report-valuation-drawer-filter-header__title {
    font-family: 'Plus Jakarta Sans';
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    color: #1e2024;
    margin: 0;
  }

  .elv-report-valuation-drawer-filter-header__operating {
    display: flex;
    align-items: center;

    svg {
      cursor: pointer;
    }

    .elv-report-valuation-drawer-filter-header__clear {
      display: flex;
      align-items: center;
      cursor: pointer;
      margin-right: 8px;

      span {
        display: block;
        color: #1e2024;
        font-family: 'Plus Jakarta Sans';
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        padding-left: 6px;
        padding-right: 8px;
        border-right: 1px solid #dde1e6;
      }
    }
  }
}
</style>

<style lang="scss">
.elv-valuation-filter-drawer-filter {
  .el-drawer__header {
    padding: 10px 0px;
    margin: 0 16px;
    border-bottom: 1px solid #d0d4d9;
    height: 48px;
    box-sizing: border-box;
    display: flex;
  }

  .el-drawer__body {
    padding: 16px 0px;
    margin-bottom: 16px;
  }

  .elv-valuation-drawer-filter-wrap {
    padding: 0px 16px;
  }

  .el-drawer__footer {
    text-align: left;
    padding: 0px 24px 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .elv-valuation-drawer-filter-item {
    display: flex;
    flex-direction: column;
    margin-bottom: 16px;

    &:last-of-type {
      margin-bottom: 0px;
    }

    .elv-valuation-drawer-filter-item-title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-family: 'Plus Jakarta Sans';
      margin-bottom: 4px;
      height: 24px;

      p {
        color: #1e2024;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
      }

      span {
        color: #5e85eb;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        cursor: pointer;
      }
    }

    .elv-select-valuation-filter-date-picker {
      border-radius: 0px 0px 4px 4px;
      border: 1px solid #dde1e6;
      box-sizing: border-box;
      height: 44px;
      box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.08);

      &.is-unEmpty {
        border-color: #5e85eb;
        background-color: #f7f9fe;
      }
    }

    .elv-select-transaction-filter-amount {
      display: flex;

      .el-input .el-input__wrapper {
        border-radius: 0px 0px 4px 4px;
        border: 1px solid #dde1e6;
        box-sizing: border-box;
        height: 44px;
        box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.08);
      }

      &.is-unEmpty .el-input .el-input__wrapper {
        border-color: #5e85eb;
        background-color: #f7f9fe;
      }

      &.is-between .el-input {
        &:first-of-type .el-input__wrapper {
          border-bottom-right-radius: 0px;
          border-right: 0px;
          position: relative;
          z-index: 20;

          &:after {
            content: '-';
            position: absolute;
            right: -4px;
            top: 50%;
            transform: translateY(-50%);
            width: fit-content;
            height: 16px;
            color: #1e2024;
            background-color: #f7f9fe;
            text-align: center;
            font-family: 'Plus Jakarta Sans';
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }

        &:last-of-type .el-input__wrapper {
          border-bottom-left-radius: 0px;
        }
      }
    }

    .elv-transaction-drawer-filter-item-referenceNo {
      &.is-unEmpty .el-input .el-input__wrapper {
        border-color: #5e85eb;
        background-color: #f7f9fe;
      }
    }

    .el-input {
      &.is-unEmpty .el-input__wrapper {
        border-color: #5e85eb;
        background-color: #f7f9fe;
      }
    }

    .elv-select-valuation-filter-amount {
      display: flex;

      .el-input .el-input__wrapper {
        border-radius: 0px 0px 4px 4px;
        border: 1px solid #dde1e6;
        box-sizing: border-box;
        height: 44px;
        box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.08);
      }

      &.is-unEmpty .el-input .el-input__wrapper {
        border-color: #5e85eb;
        background-color: #f7f9fe;
      }

      &.is-between .el-input {
        &:first-of-type .el-input__wrapper {
          border-bottom-right-radius: 0px;
          border-right: 0px;
          position: relative;
          z-index: 20;

          &:after {
            content: '-';
            position: absolute;
            right: -4px;
            top: 50%;
            transform: translateY(-50%);
            width: fit-content;
            height: 16px;
            color: #1e2024;
            background-color: #f7f9fe;
            text-align: center;
            font-family: 'Plus Jakarta Sans';
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }

        &:last-of-type .el-input__wrapper {
          border-bottom-left-radius: 0px;
        }
      }
    }
  }

  .elv-report-transaction-drawer-filter-info {
    margin-bottom: 16px;

    .elv-report-transaction-drawer-filter-info__title {
      display: flex;
      align-items: center;
      font-family: 'Barlow';
      font-weight: 500;
      font-size: 13px;
      line-height: 16px;
      color: #838d95;

      span {
        width: 1px;
        height: 12px;
        display: block;
        background: #dde1e6;
        margin: 0 6px;
      }
    }
  }
}

.el-select.is-unEmpty {
  &.is-not-underlined {
    .el-select__wrapper.is-hovering {
      border-bottom: 0px !important;
    }
  }

  .el-select__wrapper {
    border-color: #5e85eb;
    background-color: #f7f9fe;
    min-height: 44px;
    height: fit-content;

    &.is-hovering {
      box-shadow: 0px 1px 0px 0px rgba(0, 0, 0, 0.08) !important;
    }

    .el-tag {
      border-radius: 3px;
      border: 1px solid #e3e7f1;
      background: #fff;
      display: flex;
      padding: 0px 4px 0px 8px;
      justify-content: center;
      align-items: center;
      gap: 2px;
      box-sizing: border-box;
      width: fit-content;

      .el-tag__content {
        color: #0e0f11;
        font-family: 'Plus Jakarta Sans';
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 28px;

        .el-select__tags-text {
          display: flex;
          align-items: center;

          img {
            width: 14px;
            height: 14px;
            display: block;
            border-radius: 50%;
            margin-right: 4px;
          }
        }
      }

      .el-icon.el-tag__close {
        width: 12px;
        height: 12px;
        color: #aaafb6;
        margin-left: 2px;

        &:hover {
          color: #fff;
          background-color: #909399;
        }
      }
    }
  }

  .el-select__selected-item {
    display: flex;

    .el-tag {
      border-radius: 3px;
      border: 1px solid #e3e7f1;
      background: #fff;
      display: flex;
      padding: 0px 4px 0px 8px;
      justify-content: center;
      align-items: center;
      gap: 2px;
      box-sizing: border-box;
      width: fit-content;

      .el-select__tags-text {
        color: #0e0f11;
        font-family: 'Plus Jakarta Sans';
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 28px;
      }

      .el-icon.el-tag__close {
        width: 12px;
        height: 12px;
        margin-left: 2px;
      }
    }
  }
}

.el-select.is-select-value {
  .el-select__wrapper {
    border-radius: 4px 4px 0px 0px;
    box-shadow: 0px 1px 0px 0px rgba(0, 0, 0, 0.08);
    border-bottom: 0px;
  }
}

.el-select.is-select-value-top {
  .el-select__wrapper {
    border-radius: 0px 0px 4px 4px;
    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.08);
  }
}

.elv-transaction-drawer-filter-tips.el-popper {
  display: flex;
  gap: 6px;
  max-width: 400px;
  max-height: 200px;
  flex-wrap: wrap;
}

.elv-select-valuation-filter-dropdown {
  .el-select-dropdown__footer {
    padding: 0px;
  }

  .elv-select-transaction-filter-type__all,
  .elv-select-transaction-filter-currency__all {
    width: 100%;
    box-sizing: border-box;
    padding-left: 8px;
    margin-right: 0px;
    border-top: 1px solid #edf0f3;
    border-bottom: 1px solid #edf0f3;

    &:first-of-type {
      border-top: 0px;
    }

    .el-checkbox__label {
      color: #1e2024;
      font-family: 'Plus Jakarta Sans';
      font-size: 12px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
  }

  .el-checkbox-group {
    .el-checkbox {
      .el-checkbox__label {
        display: flex;
        align-items: center;

        img {
          width: 18px;
          height: 18px;
          display: block;
          border-radius: 50%;
          margin-right: 8px;
        }
      }
    }
  }

  &.journal-type {
    padding: 0px;

    .el-select-dropdown__empty {
      padding: 0px;
    }
  }

  .el-select-dropdown.is-multiple .el-select-dropdown__item {
    padding-left: 8px;

    &.selected::after,
    &.is-selected::after {
      width: 0;
      height: 0;
    }

    img {
      width: 18px;
      height: 18px;
    }
  }
}

.elv-select-valuation-filter-dropdown__position {
  .el-select-dropdown.is-multiple .el-select-dropdown__item {
    img {
      width: 14px;
      height: 14px;
    }
  }
}
</style>
