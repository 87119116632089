<template>
  <el-dialog
    v-model="showDialog"
    width="1132"
    align-center
    :close-on-press-escape="false"
    :close-on-click-modal="false"
    class="elv-valuation-filter-raw-data-dialog"
  >
    <template #header="{ titleId }">
      <h4 :id="titleId" class="elv-valuation-raw-data-dialog-header__title">
        {{ t('valuation.future.rawData') }}
      </h4>
    </template>
    <div v-loading="dialogLoading" class="elv-valuation-raw-data-dialog-content">
      <RawDataTable
        v-if="rawDataTableData.list.length"
        :tableType="props.filterType"
        :headerList="rawDataHeader"
        :tableData="rawDataTableData"
        :pageParams="rawDataPageParams"
        :hideSinglePage="true"
        :tableHeight="'auto'"
        :showScreening="false"
        @onChangePage="onChangeRawDataPage"
        @onChangePageSize="onChangeRawDataPageSize"
      />
      <div
        v-if="!rawDataTableData.list.length && rawDataType === rawDataFilterType.ENDING"
        class="elv-valuation-raw-data-dialog-content-empty-data-info"
      >
        {{
          props.filterType === valuationFilterSaveType.VALIDATOR
            ? t('message.noRawDataValidatorEndingInfo')
            : t('message.noRawDataEndingInfo')
        }}
      </div>
      <div
        v-if="!rawDataTableData.list.length && rawDataType === rawDataFilterType.WITHDRAW"
        class="elv-valuation-raw-data-dialog-content-empty-data-info"
      >
        {{ t('message.noRawDataValidatorWithdrawInfo') }}
      </div>
      <div
        v-if="!rawDataTableData.list.length && rawDataType === rawDataFilterType.DEPOSIT"
        class="elv-valuation-raw-data-dialog-content-empty-data-info"
      >
        {{ t('message.noRawDataValidatorDepositInfo') }}
      </div>
      <div
        v-if="!rawDataTableData.list.length && rawDataType === rawDataFilterType.REALIZED"
        class="elv-valuation-raw-data-dialog-content-empty-data-info"
      >
        {{ t('message.noRawDataRealizedInfo') }}
      </div>
    </div>
  </el-dialog>
</template>
<script lang="ts" setup>
import { ElMessage } from 'element-plus'
import ValuationApi from '@/api/ValuationApi'
import RawDataTable from './RawDataTable.vue'
import { useEntityStore } from '@/stores/modules/entity'
import { rawDataFilterType, valuationFilterSaveType } from '@/config/valuation'

const props = defineProps({
  filterType: {
    type: String,
    required: true,
    default: valuationFilterSaveType.FUTURE
  }
})

const { t } = useI18n()
const entityStore = useEntityStore()

const rawDataType = ref('')
const showDialog = ref(false)
const dialogLoading = ref(false)
const filterRawDataParams = ref<any>({})

const rawDataPageParams = ref({
  page: 1,
  limit: 20
})
const rawDataTableData: any = ref({
  totalCount: 0,
  list: []
})

const futureTableHeader = [
  {
    dataIndex: 'origin',
    title: t('valuation.future.origin'),
    slotName: 'origin',
    width: 100
  },
  {
    dataIndex: 'account',
    title: t('common.account'),
    slotName: 'account',
    width: 100
  },
  {
    dataIndex: 'dateTime',
    title: t('common.dateTime'),
    slotName: 'dateTime',
    width: 150
  },
  {
    dataIndex: 'referenceNo',
    title: t('report.referenceNo'),
    slotName: 'referenceNo',
    width: 150
  },
  {
    dataIndex: 'type',
    title: t('common.type'),
    slotName: 'type',
    width: 150
  },
  {
    dataIndex: 'symbol',
    title: t('valuation.future.symbol'),
    slotName: 'symbol',
    width: 400
  },
  {
    dataIndex: 'positionSide',
    title: t('report.positionAndSide'),
    slotName: 'positionSide',
    width: 100
  },
  {
    dataIndex: 'marginAsset',
    title: t('valuation.assets.marginAsset'),
    slotName: 'marginAsset',
    width: 100
  },
  {
    dataIndex: 'pAndL',
    title: t('valuation.future.PAndL'),
    slotName: 'pAndL',
    headerAlign: 'right',
    align: 'right'
  }
]

const optionsTableHeader = [
  {
    dataIndex: 'origin',
    title: t('valuation.future.origin'),
    slotName: 'origin',
    width: 100
  },
  {
    dataIndex: 'account',
    title: t('common.account'),
    slotName: 'account',
    width: 100
  },
  {
    dataIndex: 'dateTime',
    title: t('common.dateTime'),
    slotName: 'dateTime',
    width: 150
  },
  {
    dataIndex: 'referenceNo',
    title: t('report.referenceNo'),
    slotName: 'referenceNo',
    width: 150
  },
  {
    dataIndex: 'type',
    title: t('common.type'),
    slotName: 'type',
    width: 150
  },
  {
    dataIndex: 'symbol',
    title: t('valuation.future.symbol'),
    slotName: 'symbol',
    width: 400
  },
  {
    dataIndex: 'quoteAsset',
    title: t('valuation.options.quoteAsset'),
    slotName: 'quoteAsset',
    width: 100
  },
  {
    dataIndex: 'pAndL',
    title: t('valuation.future.PAndL'),
    slotName: 'pAndL',
    headerAlign: 'right',
    align: 'right'
  }
]

const validatorTableHeader = [
  {
    dataIndex: 'account',
    title: t('common.account'),
    slotName: 'account',
    width: 100
  },
  {
    dataIndex: 'dateTime',
    title: t('common.dateTime'),
    slotName: 'dateTime',
    width: 150
  },
  {
    dataIndex: 'referenceNo',
    title: t('report.referenceNo'),
    slotName: 'referenceNo',
    width: 150
  },
  {
    dataIndex: 'type',
    title: t('common.type'),
    slotName: 'type',
    width: 150
  },
  {
    dataIndex: 'asset',
    title: t('common.asset'),
    slotName: 'asset',
    width: 400
  },
  {
    dataIndex: 'amount',
    title: t('common.amount'),
    slotName: 'amount',
    headerAlign: 'right',
    align: 'right'
  }
]

const entityId = computed(() => {
  return String(entityStore.entityDetail?.entityId)
})

const rawDataHeader = computed(() => {
  switch (props.filterType) {
    case valuationFilterSaveType.FUTURE:
      return futureTableHeader
    case valuationFilterSaveType.OPTIONS:
      return optionsTableHeader
    case valuationFilterSaveType.VALIDATOR:
      return validatorTableHeader
    default:
      return []
  }
})

/**
 * 获取RawData列表数据
 */
const getRawDataList = async () => {
  dialogLoading.value = true

  const params = {
    page: rawDataPageParams.value.page,
    limit: rawDataPageParams.value.limit,
    beginningDate: filterRawDataParams.value?.beginningDate || '',
    endingDate: filterRawDataParams.value?.endingDate || '',
    sortField: 'datetime',
    sortDirection: 'DESC',
    ...(filterRawDataParams.value.positionSide ? { positionSide: filterRawDataParams.value.positionSide } : {}),
    ...(filterRawDataParams.value.origin ? { origin: filterRawDataParams.value.origin } : {}),
    ...(filterRawDataParams.value.asset?.assetId ? { assetId: filterRawDataParams.value.asset.assetId } : {}),
    ...(filterRawDataParams.value.entityAccount?.entityAccountId
      ? { entityAccountId: filterRawDataParams.value.entityAccount.entityAccountId }
      : {})
  }

  let res: any = {}
  try {
    const apiCalls = {
      [valuationFilterSaveType.FUTURE]: {
        [rawDataFilterType.ENDING]: ValuationApi.getFutureFactEndingList,
        [rawDataFilterType.REALIZED]: ValuationApi.getFutureFactRealizedList
      },
      [valuationFilterSaveType.OPTIONS]: {
        [rawDataFilterType.ENDING]: ValuationApi.getOptionsFactEndingList,
        [rawDataFilterType.REALIZED]: ValuationApi.getOptionsFactRealizedList
      },
      [valuationFilterSaveType.VALIDATOR]: {
        [rawDataFilterType.ENDING]: ValuationApi.getValidatorFactEndingList,
        [rawDataFilterType.WITHDRAW]: ValuationApi.getValidatorFactWithDrawList,
        [rawDataFilterType.DEPOSIT]: ValuationApi.getValidatorFactDepositList
      }
    }

    const fetchData = apiCalls[props.filterType]?.[rawDataType.value]
    if (fetchData) {
      if (props.filterType === valuationFilterSaveType.VALIDATOR) {
        const filterParams = {
          beginningDate: filterRawDataParams.value?.beginningDate || '',
          endingDate: filterRawDataParams.value?.endingDate || '',
          ...(filterRawDataParams.value.asset?.assetId ? { assetId: filterRawDataParams.value.asset.assetId } : {}),
          ...(filterRawDataParams.value.entityAccount?.entityAccountId
            ? { entityAccountId: filterRawDataParams.value.entityAccount.entityAccountId }
            : {})
        }
        res = await fetchData(
          entityId.value,
          {
            page: rawDataPageParams.value.page,
            limit: rawDataPageParams.value.limit
          },
          filterParams
        )
      } else {
        res = await fetchData(entityId.value, params, {})
      }
      rawDataTableData.value = res?.data
    } else {
      throw new Error('无效的过滤类型或数据类型')
    }
  } catch (error: any) {
    ElMessage.error(error.message || '获取数据失败，请重试。')
  } finally {
    dialogLoading.value = false
  }
}

/**
 * RawData分页器页码切换触发
 * @param page 页码
 */
const onChangeRawDataPage = (page: number) => {
  rawDataPageParams.value.page = page
  getRawDataList()
}

/**
 * RawData分页器每页条数切换触发
 * @param pageSize 每页条数
 */
const onChangeRawDataPageSize = (pageSize: number) => {
  rawDataPageParams.value.limit = pageSize
  getRawDataList()
}

/**
 * 打开RawData弹窗
 * @param type 弹窗类型 ENDING | REALIZED | WITHDRAW | DEPOSIT
 * @param data Calculation数据
 */
const changeDialogStatus = (type?: string, data?: any) => {
  showDialog.value = !showDialog.value
  if (showDialog.value) {
    rawDataType.value = type || rawDataFilterType.ENDING
    filterRawDataParams.value = data || {}
    getRawDataList()
  }
}

defineExpose({
  changeDialogStatus
})
</script>
<style lang="scss">
.elv-valuation-filter-raw-data-dialog {
  width: 1132px;
  box-shadow:
    0px 2px 6px rgba(0, 0, 0, 0.05),
    0px 0px 1px rgba(0, 0, 0, 0.3);
  border-radius: 6px;

  .el-dialog__header {
    text-align: center;
    font-size: 16px;
    line-height: 24px;
    height: 54px;
    font-family: 'Plus Jakarta Sans';
    font-weight: 700;
    margin: 0;
    padding: 0;
    display: flex;
    position: relative;
    align-items: center;
    justify-content: flex-start;
    color: #0e0f11;
    padding-left: 24px;
    border-bottom: 1px solid #edf0f3;

    .elv-valuation-raw-data-dialog-header__title {
      font-family: 'Plus Jakarta Sans';
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      display: flex;
      align-items: center;
      color: #0e0f11;
      margin: 0;
    }
  }

  .el-dialog__body {
    padding: 8px 24px 24px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
  }

  .el-dialog__footer {
    padding: 20px 0px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .elv-valuation-raw-data-dialog-content {
    width: 100%;
    position: relative;

    .elv-valuation-raw-data-content-panel {
      width: 100%;
      position: relative;

      .elv-valuation-raw-data-content-panel-table {
        margin-top: 0px;
        width: 100%;
        display: flex;
        justify-content: center;
      }
    }

    .elv-valuation-raw-data-dialog-content-empty-data-info {
      min-height: 120px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: nowrap;
      color: #636b75;
      font-family: 'Plus Jakarta Sans';
      font-size: 14px;
      font-weight: 400;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
</style>
